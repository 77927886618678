import { ref } from 'vue'

const useToggle = (initState = false) => {
  const show = ref(initState)
  const toggle = () => (show.value = !show.value)

  return {
    show,
    toggle
  }
}

export default useToggle
